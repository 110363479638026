<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
              v-model="item.nom"
              :error-messages="nomErrors"
              :label="$t('nom')"
              @input="$v.item.nom.$touch()"
              @blur="$v.item.nom.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <media-object v-model="item.mediaObjectImage" is-image max-height="25vh"/>
        </v-col>
      </v-row>

      <v-row>
        <!-- todo : fix the dateTime format, not supported by default on vuetify -->
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <date-time-picker v-model="item.dateDebut" label="Date début" :label="$t('dateDebut')"/>
        </v-col>
      </v-row>

      <v-row>
        <!-- todo : fix the dateTime format, not supported by default on vuetify -->
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <date-time-picker v-model="item.dateFin" :label="$t('dateFin')"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
              v-model="item.sites"
              :items="sitesSelectItems"
              :error-messages="sitesErrors"
              :no-data-text="$t('No results')"
              :label="$t('sites')"
              multiple
              item-text="nom"
              item-value="@id"
              chips
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
              v-model="item.animations"
              :items="animationsSelectItems"
              :error-messages="animationsErrors"
              :no-data-text="$t('No results')"
              :label="$t('animations')"
              multiple
              item-text="nom"
              item-value="@id"
              chips
          />
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mapActions} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import InputDate from '../InputDate';
import {date} from '../../validators/date';
import MediaObject from "../MediaObject";
import DateTimePicker from "../DateTimePicker";

export default {
  name: 'EvenementForm',
  mixins: [validationMixin],
  components: {
    DateTimePicker,
    MediaObject,
    InputDate,
  },
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {
      }
    },

    initialValues: {
      type: Object,
      default: () => {
      }
    }
  },
  mounted() {
    this.sitesGetSelectItems();
    this.animationsGetSelectItems();
  },
  data() {
    return {
      nom: null,
      dateDebut: null,
      dateFin: null,
      sites: null,
      animations: null,
    };
  },
  computed: {
    ...mapFields('site', {
      sitesSelectItems: 'selectItems'
    }),
    ...mapFields('animation', {
      animationsSelectItems: 'selectItems'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, 'nom') && errors.push(this.violations.nom);


      return errors;
    },
    dateDebutErrors() {
      const errors = [];

      if (!this.$v.item.dateDebut.$dirty) return errors;

      has(this.violations, 'dateDebut') && errors.push(this.violations.dateDebut);


      return errors;
    },
    dateFinErrors() {
      const errors = [];

      if (!this.$v.item.dateFin.$dirty) return errors;

      has(this.violations, 'dateFin') && errors.push(this.violations.dateFin);


      return errors;
    },
    sitesErrors() {
      const errors = [];

      if (!this.$v.item.sites.$dirty) return errors;

      has(this.violations, 'sites') && errors.push(this.violations.sites);


      return errors;
    },
    animationsErrors() {
      const errors = [];

      if (!this.$v.item.animations.$dirty) return errors;

      has(this.violations, 'animations') && errors.push(this.violations.animations);


      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
    ...mapActions({
      sitesGetSelectItems: 'site/fetchSelectItems'
    }),
    ...mapActions({
      animationsGetSelectItems: 'animation/fetchSelectItems'
    }),
  },
  validations: {
    item: {
      nom: {},
      dateDebut: {
        date,
      },
      dateFin: {
        date,
      },
      sites: {},
      animations: {},
    }
  }
};
</script>
